import * as React from "react";
import { Button, Col, Row } from 'reactstrap';
import Page from '../../components/Page/page';

const ChatSystemComparisonPage = ({ location }) => {
    return (
        <Page animateHeader titleClassName="text-center" title="Chat System Comparison" seoTitle="Chat System Comparison" location={location} contentClassName="panel-content p-5">
            <h2>Should you use Llama Chat, or Simple Chat?</h2>
            <p>It depends on your specific use case. In general, the simpler your needs, the more likely Simple Chat will support what you need. For complex setups where you want to retain chat across scenes, have friends and block lists, or customize regions, Llama Chat is for you.</p>
            <h2>WHAT’S THE DIFFERENCE?</h2>
            <p>Simple Chat provides a small subset of the features available in Llama Chat with minimal setup. Llama Chat provides a lot more features, flexibility, and optimizations, but takes longer to set up.</p>
            <h2>WHY SHOULD I PICK ONE OVER THE OTHER?</h2>
            <p>For games where chat is more a convenience and not a necessity, Simple Chat provides you a more basic set of chat features at a plug-and-play setup speed. Pick Llama Chat if your game is more social and needs advanced chat features.</p>
            <h2>STILL HAVE QUESTIONS?</h2>
            <p>Review the features of both and typical use cases below to see which one makes the most sense for your game. If you can’t decide between the two, feel free to send an email to support@llama.software with your game use case and we’ll work through which one makes most sense for your specific use case.</p>
            <Row>
                <Col sm="12" md="6">
                    <h3>Simple Chat</h3>
                    <p>Pros:</p>
                    <ul>
                        <li>Mirror &amp; UNET Support</li>
                        <li>Fast Setup</li>
                        <li>Easy Integration</li>
                        <li>No Coding Required</li>
                        <li>Dynamic Channels</li>
                        <li>No Database Required</li>
                        <li>Word Filters</li>
                        <li>Chat Commands</li>
                        <li>Powerful Custom Editor</li>
                        <li>Only $5</li>
                    </ul>
                    <p>Cons:</p>
                    <ul>
                        <li>Custom code required to support:</li>
                        <li>Retaining chat panel across scenes</li>
                        <li>Direct messaging between players</li>
                        <li>No worldspace chat</li>
                    </ul>
                    <p>Typical use caes:</p>
                    <ul>
                        <li>First Person Shooters</li>
                        <li>Small scale games (2-8 players)</li>
                        <li>Games utilizing 3rd party identity management tools</li>
                    </ul>
                    <p>
                        <Button color="primary" block>
                            <a className="get-it-now" href="https://assetstore.unity.com/packages/tools/network/unet-multi-channel-chat-system-66558?_aid=1101l9QvC" rel="noreferrer" target="_blank">Get Simple Chat</a>
                        </Button>
                    </p>
                </Col>
                <Col sm="12" md="6">
                    <h3>Llama Chat</h3>
                    <p>Pros:</p>
                    <ul>
                        <li>Mirror &amp; UNET Support</li>
                        <li>Advanced Dynamic Channels</li>
                        <li>Direct player messaging</li>
                        <li>Social features</li>
                        <li>Friends</li>
                        <li>Guilds</li>
                        <li>Block Players</li>
                        <li>Group Chats</li>
                        <li>Worldspace chat</li>
                        <li>Word Filters</li>
                        <li>Chat Commands</li>
                        <li>Powerful Custom Editor</li>
                    </ul>
                    <p>Cons:</p>
                    <ul>
                        <li>Database required</li>
                        <li>More complex setup</li>
                        <li>Requires some coding to fully integrate</li>
                    </ul>
                    <p>Typical use cases:</p>
                    <ul>
                        <li>Larger Scale Games (&gt;8 players) that social features will be useful</li>
                        <li>Open World or Multi-Scene games where chat retention is important</li>
                        <li>Games running external identity management, or with their own user authentication &amp; management</li>
                    </ul>
                    <p>
                        <Button color="primary" block>
                            <a className="get-it-now" rel="noreferrer" target="_blank" href="https://assetstore.unity.com/packages/tools/network/llama-chat-124562?_aid=1101l9QvC">Get Llama Chat</a>
                        </Button>
                    </p>
                </Col>
            </Row>
        </Page>
    );
};

export default ChatSystemComparisonPage;
